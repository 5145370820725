<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework Index</h3>
	<p>This interface shows all the {{framework_noun+'s'}} you are authorized to view in <span v-html="app_name"/>.</p>

	<ul>
		<li><img alt="'View As' Image" srcset="/docimages/framework_index-1.png 3x" class="k-help-img float-right">Use the <strong>View As</strong> between viewing frameworks from <strong>Map</strong> (only available in Satchel Commons or CASE Network), <strong>Tiles</strong>, or <strong>Table</strong> mode.In the tile view, click a framework’s tile to open the framework for viewing.
		</li>
	</ul>

	<p><img alt="Framework Row image" srcset="/docimages/framework_index_table.png 2x" class="k-help-img block"></p>
	
	<ul>
		<li>Click a column header in the table to sort frameworks by that column. Click the column header again to toggle between ascending and descending sorts.</li>
		<li>In either view, enter text in the search box to filter frameworks by title, category, creator, or framework identifier (GUID).</li>
		<li><b>NOTE:</b> If you are <b>looking for a framework you don’t see</b>, first try searching for the framework. If you still don’t see it and you’re not signed into <span v-html="app_name"/>, try <span v-html="link('signing_in', 'signing in')"></span>—the framework may have a “private” adoption status, in which you will only be able to view it if you are signed in and have been granted access to the framework.</li>
		<li>Email <a href="mailto:support@commongoodlt.com">support@commongoodlt.com</a> if you need help locating a framework in <span v-html="app_name"/>. We will need a link to the original source document (or PDF) as a reference.</li>
	</ul>

</div></template>

<script>
import HelpMixin from './HelpMixin'
export default {
	mixins: [HelpMixin],
}
</script>

<style lang="scss">
</style>

